import { FC } from 'react';

import { useContent } from '@sitecore/common';
import { ShopWindowRendering } from '@sitecore/types/manual/ShopWindow';
import { Box, Grid, Heading, Hidden, Invisible, PageGrid, Ribbon, Stack, Stretch } from '@sparky';

import ProductTile from './components/ProductTile';
import { useHeatpumpProducts } from './hooks/useHeatpumpProducts';

const COLUMNS_ON_MD = 2;
const COLUMNS_ON_XL = 3;

/**
 * This component will display a Ribbon spacer above the product tile if other products in the row have a Ribbon. This
 * way, the grid tile alignment will be consistent.
 *
 * It will calculate for the current productIndex what row it'd be on for MD viewports, as well as XL viewports, as
 * this is where the Grid layout changes. It then determines whether those rows on each viewport have a product with a
 * Ribbon. If so, it will add an Invisible Ribbon to the current product tile to keep the alignment consistent.
 */
const ConditionalRibbonSpacer: FC<{ productIndex: number; productWithRibbonIndex: number }> = ({
  productIndex,
  productWithRibbonIndex,
}) => {
  if (productWithRibbonIndex < 0 || productIndex === productWithRibbonIndex) return null;

  const rowWithRibbonOnMD = Math.floor(productWithRibbonIndex / COLUMNS_ON_MD);
  const rowWithRibbonOnXL = Math.floor(productWithRibbonIndex / COLUMNS_ON_XL);

  const currentRowOnMD = Math.floor(productIndex / COLUMNS_ON_MD);
  const currentRowOnXL = Math.floor(productIndex / COLUMNS_ON_XL);

  return (
    <>
      {rowWithRibbonOnMD === currentRowOnMD && (
        <Hidden below="md" above="xl">
          <Invisible>
            <Ribbon>&#160;</Ribbon>
          </Invisible>
        </Hidden>
      )}
      {rowWithRibbonOnXL === currentRowOnXL && (
        <Hidden below="xl">
          <Invisible>
            <Ribbon>&#160;</Ribbon>
          </Invisible>
        </Hidden>
      )}
    </>
  );
};

const ShopWindow: FC = () => {
  const {
    params: { backgroundColor },
    fields: { content },
    fields: {
      content: { productsList },
    },
  } = useContent<ShopWindowRendering>();
  const { data } = useHeatpumpProducts();

  const productWithRibbonIndex = productsList.findIndex(product => product.fields?.content?.ribbonText?.value);

  return (
    <Box paddingY={{ initial: '10', md: '16' }}>
      <PageGrid>
        <PageGrid.Item gridColumn="1/-1">
          <Box paddingBottom="6">
            <Heading as="h2" size="M">
              {content.title.value}
            </Heading>
          </Box>
          <Grid as="ul" columns={{ initial: 1, md: COLUMNS_ON_MD, xl: COLUMNS_ON_XL }} flow="dense" gap="10">
            {productsList.map((product, index) => (
              <Grid.Item as="li" key={product.id}>
                <Stretch height>
                  <Stack direction="column">
                    <ConditionalRibbonSpacer productIndex={index} productWithRibbonIndex={productWithRibbonIndex} />
                    <ProductTile
                      item={product}
                      productData={
                        data?.products?.find(obj => obj?.id === product?.fields?.productProperties?.productId?.value) ??
                        {}
                      }
                      backgroundColor={backgroundColor}
                    />
                  </Stack>
                </Stretch>
              </Grid.Item>
            ))}
          </Grid>
        </PageGrid.Item>
      </PageGrid>
    </Box>
  );
};

export default ShopWindow;
