import { FC } from 'react';

import { useFormatter } from '@i18n';
import { useLinkComponent } from '@link';
import { Products_External_ExternalHeatpumpProductModel } from '@monorepo-types/dc';
import { mapImage } from '@sitecore/common';
import { ProductItem } from '@sitecore/types/manual/ShopWindow';
import { Image, Text, Heading, Box, Stretch, Card, Stack, TextLink, Divider, Ribbon, Grid } from '@sparky';
import { DotIcon } from '@sparky/icons';
import { BoxProps } from '@sparky/types';

interface Props {
  item: ProductItem;
  backgroundColor?: BoxProps['backgroundColor'];
  productData: Products_External_ExternalHeatpumpProductModel;
}

const ProductTile: FC<Props> = ({ backgroundColor, item, productData }) => {
  const Link = useLinkComponent();
  const { currency } = useFormatter();
  const {
    content,
    productProperties: { propertiesList, productStockStatusOption, stockInformationLabel },
  } = item.fields;

  const stockInformation = productStockStatusOption.fields.content;
  const imageBgColor = backgroundColor || 'backgroundVarTwo';

  const specsList = propertiesList.map(spec => ({
    featured: spec.fields.content.featuredPropertyOption?.value,
    label: spec.fields.content.label.value,
    value: spec.fields.content.text.value,
  }));

  const price = {
    featured: true,
    label: content.productPriceLabel.value,
    value: productData.priceEur ? currency.euroNoFractionDigits(productData.priceEur) : '-',
  };

  specsList.push(price);
  const featuredSpecs = specsList.filter(spec => spec.featured);

  const { src, sources, alt } = mapImage(content.image);

  return (
    <Stretch height>
      <Card overflow="hidden">
        <Stretch height>
          <Stack>
            {src ? (
              <Stack.Item>
                <Box backgroundColor={imageBgColor} borderTopLeftRadius="m" borderTopRightRadius="m">
                  <Grid>
                    {content.ribbonText?.value ? <Ribbon>{content.ribbonText.value}</Ribbon> : null}
                    <Image alt={alt} width="100%" height="200px" src={src} sources={sources} objectFit="cover" />
                  </Grid>
                </Box>
              </Stack.Item>
            ) : null}

            <Stretch height>
              <Box padding="6">
                <Stretch height>
                  <Stack>
                    <Stack.Item grow>
                      {stockInformation ? (
                        <Box paddingBottom="3">
                          <Stack direction="row" gap="1" alignY="center">
                            <DotIcon color={stockInformation.feedbackColor.value} />
                            <Text size={{ initial: 'BodyS', md: 'BodyM' }} color="textLowEmphasis">
                              {stockInformationLabel
                                ? stockInformation.label.value.replace(
                                    /{stockInformationLabel}/,
                                    stockInformationLabel.value,
                                  )
                                : stockInformation.label.value}
                            </Text>
                          </Stack>
                        </Box>
                      ) : null}

                      <Heading as="h3" size="XS">
                        <Box paddingBottom="3">{content.productNameLabel.value}</Box>
                        <Text color="textLowEmphasis" size="BodyS">
                          {content.productTypeLabel.value}
                        </Text>
                      </Heading>
                    </Stack.Item>

                    <Box paddingY="6">
                      <Divider />
                    </Box>

                    <Stack.Item>
                      {featuredSpecs.map(spec => (
                        <Box paddingBottom="3" key={spec.label}>
                          <Text as="div" size="BodyS" weight="bold" color="textLowEmphasis">
                            {spec.label}
                          </Text>
                          <Text as="div" size="BodyM" weight="bold">
                            {spec.value ?? '-'}
                          </Text>
                        </Box>
                      ))}
                    </Stack.Item>

                    <Box paddingTop={{ initial: '3' }}>
                      <Stretch width={{ md: false, sm: true }}>
                        {content.link?.value.href && content.link.value.text ? (
                          <Stretch width={{ initial: true, md: false }}>
                            <Link href={content.link.value.href} linkType={content.link.value.linktype}>
                              <TextLink
                                emphasis="high"
                                ariaLabel={`${content.link.value.text} | ${content.productNameLabel.value} ${content.productTypeLabel.value}`}>
                                {content.link.value.text}
                              </TextLink>
                            </Link>
                          </Stretch>
                        ) : null}
                      </Stretch>
                    </Box>
                  </Stack>
                </Stretch>
              </Box>
            </Stretch>
          </Stack>
        </Stretch>
      </Card>
    </Stretch>
  );
};

export default ProductTile;
